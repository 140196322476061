import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useGetLivingWageSurveyResultQuery } from '../../store/api';
import { CSVLink } from 'react-csv';
import { LoadingContainer, Info } from './style';
import useCalculations from '../../hook/calculations';
import { GiWoodPile } from 'react-icons/gi';
import { GiElectric } from 'react-icons/gi';
import { SiFireship } from 'react-icons/si';
import { Col, Row, Button, Spin, Card, Table } from 'antd';
import PageTitle from '../../components/PageTitle';

const headers = [
	{ label: 'Haneye gelir getiren kişi sayısı', key: 'number_of_people_generate_income' },
	{ label: 'Hanedeki çocuk sayısı', key: 'number_of_children' },
	{ label: 'Eğitim Alan yetişkin Sayısı', key: 'adult_in_eduction' },
	{ label: 'Isınma Tipi', key: 'energy_source' },
	{ label: 'Ulaşım', key: 'transport_vehicle' },
	{ label: 'Ev-İş Arası Mesafe', key: 'distance_home_to_work' },
	{ label: 'En yakın AVM mesafesi', key: 'distance_shop_to_home' },
	{ label: 'En yakın Okul', key: 'distance_school_to_home' },
	{ label: 'En yakın sağlık kuruluşu', key: 'distance_hospital_to_home' },
	{ label: 'İçme suyu erişimi', key: 'access_to_water' },
	{ label: 'Eğitim Masrafı', key: 'education_expense' },
	{ label: 'Ulaşım Masrafı', key: 'transportation_expense' },
	{ label: 'Gıda Masrafı', key: 'food_expense' },
	{ label: 'Giyim Masrafı', key: 'clothing_expense' },
	{ label: 'Enerji ve Su giderleri', key: 'fuel_and_water_expense' },
	{ label: 'Barınma Giderleri', key: 'housing_expense' },
	{ label: 'Beklenmeyen Gider', key: 'unexpected_expense' },
	{ label: 'Birikim', key: 'savings' },
];

const tableHeader = [
	{
		title: 'Haneye gelir getiren kişi sayısı',
		key: 'number_of_people_generate_income',
		dataIndex: 'number_of_people_generate_income',
	},
	{ title: 'Hanedeki çocuk sayısı', key: 'number_of_children', dataIndex: 'number_of_children' },
	{
		title: 'Eğitim Alan yetişkin Sayısı',
		key: 'adult_in_eduction',
		dataIndex: 'adult_in_eduction',
	},
	{ title: 'Isınma Tipi', key: 'energy_source', dataIndex: 'energy_source' },
	{ title: 'Ulaşım', key: 'transport_vehicle', dataIndex: 'transport_vehicle' },
	{ title: 'Ev-İş Arası Mesafe', key: 'distance_home_to_work', dataIndex: 'distance_home_to_work' },
	{
		title: 'En yakın AVM mesafesi',
		key: 'distance_shop_to_home',
		dataIndex: 'distance_shop_to_home',
	},
	{ title: 'En yakın Okul', key: 'distance_school_to_home', dataIndex: 'distance_school_to_home' },
	{
		title: 'En yakın sağlık kuruluşu',
		key: 'distance_hospital_to_home',
		dataIndex: 'distance_hospital_to_home',
	},
	{ title: 'İçme suyu erişimi', key: 'access_to_water', dataIndex: 'access_to_water' },
	{ title: 'Eğitim Masrafı', key: 'education_expense', dataIndex: 'education_expense' },
	{ title: 'Ulaşım Masrafı', key: 'transportation_expense', dataIndex: 'transportation_expense' },
	{ title: 'Gıda Masrafı', key: 'food_expense', dataIndex: 'food_expense' },
	{ title: 'Giyim Masrafı', key: 'clothing_expense', dataIndex: 'clothing_expense' },
	{
		title: 'Enerji ve Su giderleri',
		key: 'fuel_and_water_expense',
		dataIndex: 'fuel_and_water_expense',
	},
	{ title: 'Barınma Giderleri', key: 'housing_expense', dataIndex: 'housing_expense' },
	{ title: 'Beklenmeyen Gider', key: 'unexpected_expense', dataIndex: 'unexpected_expense' },
	{ title: 'Birikim', key: 'savings', dataIndex: 'savings' },
];

const SurveyDetail = () => {
	const { season } = useParams();
	const { data, isError, isLoading } = useGetLivingWageSurveyResultQuery(season);

	const { avarage, energySourceElectric, energySourceStove, energySourceGas } =
		useCalculations(data);

	if (isLoading) {
		return (
			<LoadingContainer>
				<Spin percent="auto" size="large" />
			</LoadingContainer>
		);
	}

	if (isError) {
		return (
			<Alert
				message={`${season} "yılına ait ankette veri bulunamadı."`}
				type="error"
				variant="danger"
			></Alert>
		);
	}

	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title={`${season} Anket Sonuçları`}>
					<Button as={CSVLink} data={data} separator={';'} headers={headers}>
						Excel'e Aktar
					</Button>
				</PageTitle>
			</Col>
			<Col xs={24} xl={16} style={{ marginBottom: '20px' }}>
				<Row gutter={[32, 16]}>
					<Col xs={24} xl={8}>
						<Card
							title="Soba ile ısınan Çalışanların Yüzdesi"
							bordered={false}
							style={{
								width: 300,
							}}
						>
							% {energySourceStove} <GiWoodPile />
						</Card>
					</Col>
					<Col xs={24} xl={8}>
						<Card
							title="Doğalgaz ile ısınan Çalışanların Yüzdesi"
							bordered={false}
							style={{
								width: 300,
							}}
						>
							% {energySourceGas} <SiFireship />
						</Card>
					</Col>
					<Col xs={24} xl={8}>
						<Card
							title="Elektrik ile ısınan Çalışanların Yüzdesi"
							bordered={false}
							style={{
								width: 300,
							}}
						>
							% {energySourceElectric}
							<GiElectric />
						</Card>
					</Col>
				</Row>
			</Col>
			<Col xs={24} xl={16} style={{ marginBottom: '20px' }}>
				<Alert variant="primary">Ortalama : {avarage} TL</Alert>
			</Col>

			<Col xs={24} xl={16}>
				{data && (
					<Table
						pagination={false}
						scroll={{
							x: 'min-content',
						}}
						size="middle"
						dataSource={data}
						columns={tableHeader}
					/>
				)}
			</Col>
		</>
	);
};

export default SurveyDetail;
