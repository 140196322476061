import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetComplaintQuery } from '../../store/api';
import { useGetOffersQuery } from '../../store/api';
import { useSelector } from 'react-redux';
import { Card, Button, Divider, Row, Flex, Col } from 'antd';
import PageTitle from '../../components/PageTitle';
const Home = () => {
	const { data: complaint } = useGetComplaintQuery();
	const { data: offer } = useGetOffersQuery();
	const { userInfo } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Anasayfa" className="fw-bold"></PageTitle>
			</Col>

			<Col xs={24} xl={16}>
				<Row>
					<Col xs={{ span: 6 }} lg={{ span: 6 }}>
						<Card
							title={
								complaint?.complaint.length > 0
									? 'Bekleyen şikayetleriniz var! '
									: 'Harika Hiç Bekleyen Şikayetiniz Yok.'
							}
						>
							Zamanında aksiyon almak için şikayet sayfasına gidip şikayetleri düzenleyebilirsiniz.
							<Button
								color="primary"
								variant="filled"
								onClick={() => navigate('/complaints')}
								style={{ marginTop: '20px' }}
							>
								Şikayet Sayfasına Git
							</Button>
							<Divider />
							<div className="additional">
								<p className="price">Bekleyen şikayet sayısı: {complaint?.complaint.length}</p>
							</div>
						</Card>
					</Col>
					<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6 }}>
						<Card
							title={
								offer?.offer.length > 0
									? 'Çalışanlarınızdan gelen önerilieriniz var!'
									: 'Çalışanlardan Öneriler Duyabilsek Harika Olmaz mı?'
							}
						>
							Önerileri gözden geçirip iyiliştirme alanları belirleyebilirsiniz.
							<Button onClick={() => navigate('/offers')} color="primary" variant="filled">
								Öneri Sayfasına Git
							</Button>
							<Divider />
							<div className="additional">
								<p className="price">Bekleyen öneri sayısı: {offer?.offer.length}</p>
							</div>
						</Card>
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default Home;
