import React, { useState } from 'react';
import {
	useGetLocationsQuery,
	useGetWorkersListQuery,
	useUpdateWorkerMutation,
} from '../../store/api';
import { Button, Space, Table, Badge, Switch, Col } from 'antd';
import AddWorkerPopup from './addWorkersSingle';
import UploadWithExcel from './uploadWithExcel';
import AddUnit from './AddUnit';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';

const WorkersList = () => {
	const [pagination, setPagination] = useState({ limit: 10, offset: 0 });

	const { data, refetch } = useGetWorkersListQuery(pagination);
	const [updateWorker, { isLoading }] = useUpdateWorkerMutation();
	const [modalData, setModalData] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const addWorker = () => {
		setModalData(true);
		refetch();
	};

	const addUnit = () => {
		setIsAddUnitModalOpen(true);
	};

	const handleChange = (isActive, id) => {
		const status = isActive === 1 ? 0 : 1;
		const data = { isActive: status, id: id };
		updateWorker(data);
		refetch();
	};

	const onChangePage = (page, pageSize) => {
		setPagination({ limit: pageSize, offset: (page - 1) * pageSize });
	};

	const columns = [
		{
			title: 'Telefon Numarası',
			dataIndex: 'phone_number',
			key: 'phone_number',
		},
		{
			title: 'Departman',
			dataIndex: 'department',
			key: 'department',
		},
		{
			title: 'Durum',
			dataIndex: 'isActive',
			key: 'isActive',
			render: (row, item) => (
				<Badge
					style={{ backgroundColor: row === 1 ? '#52c41a' : '' }}
					count={row === 1 ? 'Aktif' : 'Pasif'}
				/>
			),
		},
		{
			title: 'Düzenle',
			key: 'delete',
			sorter: true,
			render: (item, row) => (
				<Space size="middle">
					<Switch
						onClick={() => handleChange(row.isActive, row.id)}
						checked={row.isActive}
					></Switch>
				</Space>
			),
		},
	];

	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Aksiyon Alınan Dilek - Şikayetler">
					<div>
						<Button onClick={addUnit} size="sm" variant="danger" className="me-2">
							Departman ve Bölüm Ekle
						</Button>
						<Button onClick={addWorker} size="sm" variant="danger" className="me-2">
							Hızlı Çalışan Ekle
						</Button>
						<Button type="primary" onClick={showModal}>
							Excel ile Çalışan Aktar
						</Button>
					</div>
				</PageTitle>
			</Col>
			<AddWorkerPopup
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<AddUnit open={isAddUnitModalOpen} onClose={() => setIsAddUnitModalOpen(false)} />
			<UploadWithExcel isShow={isModalOpen} onClose={closeModal} />
			<Col xs={24} xl={16} styles={{ marginBottom: 20 }}>
				<Table
					dataSource={data?.workersList || []}
					columns={columns}
					pagination={{ total: data?.total, onChange: onChangePage, showSizeChanger: true }}
				/>
			</Col>
		</>
	);
};

export default WorkersList;
