import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useGetOffersQuery } from '../../store/api';
import CustomModal from '../../components/Modals';
import ComplaintCard from '../../components/ComplaintCard';
import { Button, Alert, Row, Col } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';
import PageTitle from '../../components/PageTitle';

const Offer = () => {
	const { data: offer, isLoading } = useGetOffersQuery();
	const [modalData, setModalData] = useState();
	const [openExportExcel, setOpenExportExcel] = useState(false);
	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Aksiyon Alınan Dilek - Şikayetler">
					<Button onClick={() => setOpenExportExcel(true)}>Excel'e Aktar</Button>
				</PageTitle>
			</Col>
			<Col xs={24} lg={16}>
				{offer?.message && (
					<Alert message="Şu an öneri bulunmamaktadır. " type="error" variant="danger"></Alert>
				)}
				{isLoading && (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Yükleniyor...</span>
					</Spinner>
				)}
			</Col>
			<Col xs={24} xl={16}>
				<Row justify="start" gutter={[16, 16]}>
					{offer?.offer?.map((item, index) => {
						return (
							<Col
								style={{ marginBottom: 20 }}
								key={item.id}
								xs={24}
								xl={{ span: 6, offset: index % 3 ? 1 : 0 }}
							>
								<ComplaintCard
									item={item}
									onClick={() => setModalData({ ...item, action: true })}
								/>
							</Col>
						);
					})}
				</Row>
			</Col>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={0}
				choiseType="offer"
			/>
		</>
	);
};

export default Offer;
