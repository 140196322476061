import { Card, Badge } from 'antd';
import filterBadWords from '../../utils/BadWords';
import { CardMeta } from './styles';
import selectCategory from '../../utils/Category';

const ComplaintCard = ({ item, onClick }) => {
	//console.log('item', selectCategory[item.category].label);

	const cat =
		item.category && item.category !== '' ? selectCategory[item.category].label : 'Kategorisiz';
	return (
		<Badge.Ribbon text={cat} color={item.isActive ? 'green' : 'red'}>
			<Card title={filterBadWords(item.topic)} hoverable onClick={onClick}>
				<CardMeta description={filterBadWords(item.comment)} />
			</Card>
		</Badge.Ribbon>
	);
};

export default ComplaintCard;
