import React, { useState } from 'react';
import { useGetComplaintQuery, useGetOffersQuery } from '../../store/api';
import { Button, Row, Col, Card } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';
import CustomModal from '../../components/Modals';
import ComplaintCard from '../../components/ComplaintCard';
import PageTitle from '../../components/PageTitle';
import selectCategory from '../../utils/Category';

const ReportPage = () => {
	const [openExportExcel, setOpenExportExcel] = useState(false);
	const { data: complaint, isLoading: complaintLoading } = useGetComplaintQuery();
	const { data: offer, isLoading: offerLoading } = useGetOffersQuery();
	const [modalData, setModalData] = useState();

	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Aksiyon Alınan Dilek - Şikayetler">
					<Button onClick={() => setOpenExportExcel(true)}>Excel'e Aktar</Button>
				</PageTitle>
			</Col>
			<Col xs={24} xl={16}>
				<Row justify="start" gutter={[16, 16]}>
					{complaint?.complaint
						?.filter((item) => item.complaintStatus === 1)
						.map((item, index) => {
							return (
								<Col
									style={{ marginBottom: 20 }}
									key={item.id}
									xs={{ span: 24 }}
									lg={{ span: 6, offset: index % 3 ? 1 : 0 }}
								>
									<ComplaintCard
										item={item}
										onClick={() => setModalData({ ...item, action: true })}
									/>
								</Col>
							);
						})}

					{offer?.offer
						?.filter((item) => item.complaintStatus === 1)
						.map((item, index) => {
							return (
								<Col
									style={{ marginBottom: 20 }}
									key={item.id}
									xs={{ span: 6 }}
									lg={{ span: 6, offset: index % 3 ? 1 : 0 }}
								>
									<ComplaintCard
										item={item}
										onClick={() => setModalData({ ...item, action: true })}
									/>
								</Col>
							);
						})}
				</Row>
			</Col>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={1}
			/>
		</>
	);
};

export default ReportPage;
