import React from 'react';
import { Row, Col, Button, Spin, Alert, Flex } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useGetLivingWageSurveysQuery } from '../../store/api';
import PageTitle from '../../components/PageTitle';

const Survey = () => {
	const { data, isLoading } = useGetLivingWageSurveysQuery();
	const navigate = useNavigate();
	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Anketler"></PageTitle>

				{!data && (
					<Alert
						message="Henüz düzenlenmiş bir anket bulunmamaktadır."
						type="error"
						variant="danger"
					></Alert>
				)}
				{isLoading && <Spin tip="Loading"></Spin>}
			</Col>
			<Col xs={24} xl={16}>
				{data &&
					data.map((item, index) => (
						<>
							<div>{item.season} - Living wage anketi</div>
							<div style={{ textAlign: 'right' }}>
								<Button
									variant="outline-primary"
									size="sm"
									onClick={() => navigate(`/survey/${item.season}`)}
								>
									Detay
								</Button>
							</div>
						</>
					))}
			</Col>
		</>
	);
};

export default Survey;
