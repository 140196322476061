import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUserLoginMutation } from '../../store/api';
import Spinner from 'react-bootstrap/Spinner';
import { LoginContainer, Desc } from './styled';
import { ErrorMessage } from '@hookform/error-message';
import { BsBoxArrowInRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, theme, Typography, Grid } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const LoginScreen = () => {
	const { t, i18n } = useTranslation();
	const [loginUser, { isLoading, error, reset }] = useUserLoginMutation();
	const navigate = useNavigate();

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	const onFinish = async (values) => {
		const res = await loginUser(values);
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const { token } = useToken();
	const screens = useBreakpoint();

	const styles = {
		container: {
			margin: '0 auto',
			padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
			width: '380px',
		},
		footer: {
			marginTop: token.marginLG,
			textAlign: 'center',
			width: '100%',
		},
		forgotPassword: {
			float: 'right',
		},
		header: {
			marginBottom: token.marginXL,
			textAlign: 'center',
		},
		section: {
			alignItems: 'center',
			backgroundColor: token.colorBgContainer,
			display: 'flex',
			height: screens.sm ? '100vh' : 'auto',
		},
		text: {
			color: token.colorTextSecondary,
		},
		title: {
			fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
		},
	};

	return (
		<section style={styles.section}>
			<div style={styles.container}>
				<div style={{ marginBottom: '50px', textAlign: 'center' }}>
					<Button type="text" variant="link" onClick={() => changeLanguage('tr')}>
						TR
					</Button>
					|
					<Button type="text" variant="link" onClick={() => changeLanguage('en')}>
						EN
					</Button>
				</div>
				<div style={styles.header}>
					<img src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`} alt="voiceofworkers logo" />
					<Text style={styles.text}>
						<Desc className="text-dark"> {t('login.desc')}</Desc>
					</Text>
				</div>
				<Form
					name="normal_login"
					initialValues={{
						remember: true,
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					layout="vertical"
					requiredMark="optional"
				>
					<Form.Item
						name="user_name"
						rules={[
							{
								required: true,
								message: 'Please input your username!',
							},
						]}
					>
						<Input prefix={<MailOutlined />} placeholder="Email" />
					</Form.Item>
					<Form.Item
						name="user_password"
						rules={[
							{
								required: true,
								message: 'Please input your Password!',
							},
						]}
					>
						<Input.Password prefix={<LockOutlined />} type="password" placeholder="Password" />
					</Form.Item>
					<Form.Item>
						<Button type="link" style={styles.forgotPassword}>
							{t('login.forgot_password')}
						</Button>
					</Form.Item>
					<Form.Item style={{ marginBottom: '0px' }}>
						<Button block="true" type="primary" htmlType="submit">
							<BsBoxArrowInRight /> {isLoading ? t('login.login_redirect') : t('login.login_btn')}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</section>
	);
};

export default LoginScreen;
