import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../store/authSlice';
import {
	BsHouse,
	BsSendDash,
	BsSendPlus,
	BsUiChecksGrid,
	BsMegaphone,
	BsGear,
	BsBoxArrowLeft,
} from 'react-icons/bs';
import { Menu, Row, Col, Button, Flex, Layout as ANTDLayout } from 'antd';
const { Header, Footer, Content } = ANTDLayout;

const items = [
	{
		label: 'Anasayfa',
		key: '/',
		icon: <BsHouse />,
		theme: 'dark',
	},
	{
		label: 'Şikayetler',
		key: '/complaints',
		icon: <BsSendDash />,
	},
	{
		label: 'Öneriler',
		key: '/offers',
		icon: <BsSendPlus />,
	},
	{
		label: 'Aksiyon Alınanlar',
		key: '/reports',
		icon: <BsSendPlus />,
	},
	{
		label: 'Anketler',
		key: '/survey',
		icon: <BsUiChecksGrid />,
	},
	{
		label: 'Duyurular',
		key: '/announce',
		icon: <BsMegaphone />,
	},
	{
		label: 'Çalışan Listesi',
		key: '/workers-list',
		icon: <BsBoxArrowLeft />,
	},
	{
		label: 'Ayarlar',
		key: '/settings',
		icon: <BsGear />,
	},
];

const Layout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const onClick = (e) => {
		navigate(e.key);
	};

	return (
		<Flex gap="middle" wrap style={{ height: '100vh' }}>
			<ANTDLayout>
				<Header style={{ background: '#f1f1f1' }}>
					<Row justify="space-between" align="center">
						<Col>
							<img
								src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`}
								alt="voiceofworkers logo"
								style={{ padding: '10px 0' }}
							/>
						</Col>
						<Col>
							<Button size="sm" type="primary" onClick={() => dispatch(logout())}>
								<BsBoxArrowLeft /> Çıkış Yap
							</Button>
						</Col>
					</Row>
				</Header>
				<Content>
					<Row style={{ background: '#2e77fb' }} justify="center">
						<Menu
							onClick={onClick}
							mode="horizontal"
							items={items}
							theme="dark"
							style={{
								width: '70%',
								textAlign: 'center',
								background: '#2e77fb',
								padding: '20px 0',
								fontSize: '16px',
							}}
						/>
					</Row>
					<Row justify="center">
						<Outlet />
					</Row>
				</Content>
				<Footer style={{ textAlign: 'center' }}>voiceofworkers 2024</Footer>
			</ANTDLayout>
		</Flex>
	);
};

export default Layout;
