import { Nav } from 'react-bootstrap';
import { Button, Flex, Row, Col, Menu, Layout as ANTDLayout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import {
	BsHouse,
	BsSendDash,
	BsSendPlus,
	BsUiChecksGrid,
	BsMegaphone,
	BsGear,
	BsBoxArrowLeft,
} from 'react-icons/bs';
import { logout } from '../../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Header, Footer, Content } = ANTDLayout;

const items = [
	{
		label: 'Anasayfa',
		key: '/',
		icon: <BsHouse />,
		theme: 'dark',
	},
	{
		label: 'Şikayetler',
		key: '/complaints',
		icon: <BsSendDash />,
	},
	{
		label: 'Anketler',
		key: '/survey',
		icon: <BsSendDash />,
	},
	{
		label: 'Ayarlar',
		key: '/settings',
		icon: <BsSendDash />,
	},
];
const AdminHome = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const onClick = (e) => {
		console.log(e.key);
		navigate(e.key);
	};
	return (
		<Flex gap="middle" wrap style={{ height: '100vh' }}>
			<ANTDLayout>
				<Header style={{ background: '#f1f1f1' }}>
					<Row justify="space-between" align="center">
						<Col>
							<img
								src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`}
								alt="voiceofworkers logo"
								style={{ padding: '10px 0' }}
							/>
						</Col>
						<Col>
							<Button size="sm" type="primary" onClick={() => dispatch(logout())}>
								<BsBoxArrowLeft /> Çıkış Yap
							</Button>
						</Col>
					</Row>
				</Header>
				<Content>
					<Row style={{ background: '#2e77fb' }} justify="center">
						<Menu
							onClick={onClick}
							mode="horizontal"
							items={items}
							theme="dark"
							style={{
								width: '70%',
								textAlign: 'center',
								background: '#2e77fb',
								padding: '20px 0',
								fontSize: '16px',
							}}
						/>
					</Row>
					<Row justify="center">
						<Outlet />
					</Row>
				</Content>
				<Footer style={{ textAlign: 'center' }}>voiceofworkers 2024</Footer>
			</ANTDLayout>
		</Flex>
	);
};

const AdminLayout = () => {
	return <AdminHome />;
};

export default AdminLayout;
