import { Button, Divider, Form, Input, Modal, Select, Space, Table } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	useAddDepartmentAndUnitsMutation,
	useGetDepartmentsQuery,
	useGetLocationsQuery,
	useLazyGetUnitsQuery,
} from '../../store/api';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

const AddUnit = ({ open, onClose }) => {
	const { data: locationsData, isLoading: isLocationLoading } = useGetLocationsQuery();
	const [addDepartmentAndUnits] = useAddDepartmentAndUnitsMutation();
	const [getUnit, { data: getUnitData }] = useLazyGetUnitsQuery();
	const [departmentSearch, setDepartmentSearch] = useState('');
	const [addDepartmentText, setAddDepartmantText] = useState('');
	const [addDepartment, setAddDepartmant] = useState();
	const [searchTerm] = useDebounce(departmentSearch, 400);

	const { data, isFetching, isLoading } = useGetDepartmentsQuery(searchTerm, {
		refetchOnMountOrArgChange: true,
	});
	const [form] = Form.useForm();
	const submit = async (values) => {
		addDepartmentAndUnits(values);
		handleClose();
	};

	const handleClose = () => {
		form.resetFields();
		if (locationsData?.locations?.length > 0) {
			form.setFieldValue('location', locationsData.locations[0].id);
		}
		onClose();
	};

	const department = Form.useWatch('department', form);
	const location = Form.useWatch('location', form);
	const units = Form.useWatch('units', form);

	useEffect(() => {
		console.log(department);
		getUnit(department);
	}, [department]);

	useEffect(() => {
		if (locationsData?.locations?.length > 0) {
			form.setFieldValue('location', locationsData.locations[0].id);
		}
	}, [locationsData]);

	const departments = useMemo(() => {
		let res = [];
		if (data !== undefined) {
			res = data.departments.map((item) => ({ value: item.id, label: item.department_name }));
		}

		if (addDepartment) {
			res.push({ id: addDepartment, value: addDepartment });
		}

		return res;
	}, [data, addDepartment]);

	const onClickAddDepartment = () => {
		setAddDepartmant(addDepartmentText);
		setAddDepartmantText('');
	};

	const columns = [
		{
			title: 'Bölüm Adı',
			dataIndex: 'unit_name',
			key: 'unit_name',
		},
		{
			title: 'Çalışan Sayısı',
			dataIndex: 'worker_count',
			key: 'worker_count',
		},
		{
			title: 'Düzenle',
			key: 'delete',
			sorter: true,
			render: (item, index, row) => (
				<Space size="middle">
					<Button
						danger
						size="small"
						onClick={() => console.log(item.id)}
						icon={<DeleteOutlined />}
					></Button>
				</Space>
			),
		},
	];

	return (
		<Modal
			okButtonProps={{
				htmlType: 'submit',
				form: 'add-unit-form',
				disabled: location === undefined || department === undefined,
			}}
			open={open}
			onCancel={handleClose}
			onClose={handleClose}
			title="Departman ve Bölüm Ekle"
		>
			<Form id="add-unit-form" form={form} onFinish={submit}>
				<Form.Item name="location" label="Location">
					<Select
						options={
							locationsData?.locations?.map((item) => ({
								value: item.id,
								label: item.location_name,
							})) || []
						}
						loading={isLocationLoading}
						allowClear={false}
						defaultActiveFirstOption={true}
					/>
				</Form.Item>
				<Form.Item name="department" label="Departman">
					<Select
						options={departments}
						showSearch={true}
						loading={isFetching || isLoading}
						onSearch={(text) => setDepartmentSearch(text)}
						filterOption={false}
						dropdownRender={(menu) => (
							<>
								{menu}
								{addDepartment === undefined && (
									<>
										<Divider
											style={{
												margin: '8px 0',
											}}
										/>
										<Space
											style={{
												padding: '0 8px 4px',
											}}
										>
											<Input
												placeholder="Yeni Departman Adı"
												onKeyDown={(e) => e.stopPropagation()}
												onChange={(e) => setAddDepartmantText(e.target.value)}
												value={addDepartmentText}
											/>
											<Button type="text" icon={<PlusOutlined />} onClick={onClickAddDepartment}>
												Departman Ekle
											</Button>
										</Space>
									</>
								)}
							</>
						)}
					/>
				</Form.Item>
				<Space
					direction="vertical"
					size="middle"
					style={{
						display: 'flex',
					}}
				>
					{getUnitData?.units.length > 0 && (
						<Table
							rowKey={'id'}
							columns={columns}
							dataSource={getUnitData?.units}
							pagination={false}
						/>
					)}

					<Form.List name={'units'}>
						{(fields, { add, remove }, { errors }) => (
							<>
								<Button
									disabled={department === undefined}
									style={{ marginBottom: 16 }}
									icon={<PlusOutlined />}
									onClick={() => add()}
								>
									Yeni Bölüm Ekle
								</Button>
								{fields.map((field, index) => (
									<Space.Compact
										style={{
											width: '100%',
										}}
										key={index}
									>
										<Form.Item {...field} style={{ width: '100%' }}>
											<Input />
										</Form.Item>
										<Button
											danger
											icon={<DeleteOutlined />}
											onClick={() => remove(field.name)}
										></Button>
									</Space.Compact>
								))}
							</>
						)}
					</Form.List>
				</Space>
			</Form>
		</Modal>
	);
};

export default AddUnit;
