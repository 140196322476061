import React, { useEffect } from 'react';
import { Button, Form, Input, Row, Col, Card } from 'antd';
import { useSetAddAnnounceMutation } from '../../store/api';
import { useForm } from 'react-hook-form';
import AnnounceCard from './announceCard';
import PageTitle from '../../components/PageTitle';

const Announce = () => {
	const [addAnnounce, { isLoading: addAnnounceLoading, errors }] = useSetAddAnnounceMutation();

	const [form] = Form.useForm();

	const onFinish = async (values) => {
		await addAnnounce(values);
		form.resetFields();
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Duyurular"></PageTitle>
			</Col>
			<Col xs={2} sm={4} md={6} lg={8} xl={16}>
				<Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
					<Col span={24}>
						<Card>
							<Form
								name="basic"
								layout="vertical"
								initialValues={{
									remember: false,
								}}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								autoComplete="off"
								form={form}
							>
								<Form.Item
									label="Duyuru Başlığı"
									name="topic"
									rules={[
										{
											required: true,
											message: 'Lütfen Duyuru Başlığı Giriniz!',
										},
									]}
								>
									<Input name="topic" placeholder="Duyuru başlığı" />
								</Form.Item>
								<Form.Item
									label="Duyuru İçeriği"
									name="comment"
									rules={[
										{
											required: true,
											message: 'Lütfen Duyuru İçeriği Giriniz!',
										},
									]}
								>
									<Input.TextArea name="comment" placeholder="Duyuru İçeriği" />
								</Form.Item>
								<Form.Item>
									<Button loading={addAnnounceLoading} type="primary" htmlType="submit">
										Gönder
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</Col>
			<Col xs={24} xl={16}>
				<PageTitle title="Geçmiş Duyurular"></PageTitle>
			</Col>
			<Col xs={24} xl={16}>
				<Row>
					<Col className="gutter-row" span={24} mb={4}>
						<AnnounceCard />
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default Announce;
