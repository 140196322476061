import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useAddWorkerMutation } from '../../store/api';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from './styled';
import { Modal, Form, Input } from 'antd';

const AddWorkerPopup = ({ item, isShow, handleClose }) => {
	const [addWorker, { data, isLoading }] = useAddWorkerMutation();
	const [form] = Form.useForm();

	const updateAnnouncement = async (data) => {
		await addWorker(data);
		form.resetFields();
		handleClose();
	};

	const closeButton = () => {
		handleClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<Modal
				title="Çalışan Telefon Numarası Ekle"
				open={isShow}
				okButtonProps={{
					htmlType: 'submit',
					form: 'custom-modal-form',
				}}
				onCancel={handleClose}
			>
				<Form
					id="custom-modal-form"
					name="basic"
					layout="vertical"
					initialValues={{
						remember: true,
					}}
					onFinish={updateAnnouncement}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					form={form}
				>
					{data && data?.message && (
						<Alert size="sm" variant="info">
							{data?.message}
						</Alert>
					)}

					<Form.Item
						label="Telefon numarası"
						name="phone_number"
						rules={[
							{
								required: true,
								message: 'Telefon numarası zorunludur.',
								minLength: 10,
								maxLength: 13,
								pattern:
									/^(\+90|0)?\s*(\(\d{3}\)[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}|\(\d{3}\)[\s-]*\d{3}[\s-]*\d{4}|\(\d{3}\)[\s-]*\d{7}|\d{3}[\s-]*\d{3}[\s-]*\d{4}|\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2})$/,
							},
						]}
					>
						<Input name="phone_number" placeholder="+905554443322" />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddWorkerPopup;
