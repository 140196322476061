import React, { useEffect, useState } from 'react';
import { useUpdateaAnnounceMutation } from '../../store/api';
import { Modal, List, Space, Form, Input, Button, Spin, Col, Alert } from 'antd';
import { useGetAnnouncesQuery } from '../../store/api';

const AnnounceCard = () => {
	const { data: annnounce, isLoading: announceLoading, refetch } = useGetAnnouncesQuery();
	const [update, { isLoading }] = useUpdateaAnnounceMutation();
	const [show, setShow] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [form, watch] = Form.useForm();

	useEffect(() => {
		refetch();
	}, [annnounce]);

	const updateAnnounce = (item) => {
		setModalData(item);
		setShow(true);
	};

	const updateAnnouncement = async (data) => {
		console.log('data', modalData);
		await update({
			...data,
			id: modalData.id,
		});
		form.resetFields();
	};

	const handleClose = () => setShow(false);
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<Space
			direction="vertical"
			size="middle"
			style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}
		>
			{announceLoading && <Spin percent="auto" size="large" />}
			{annnounce?.message && (
				<Col xs={12}>
					<Alert variant="danger">Şu an duyuru bulunmamaktadır. </Alert>
				</Col>
			)}

			{annnounce && (
				<List
					style={{ background: 'white' }}
					className="demo-loadmore-list"
					bordered
					itemLayout="horizontal"
					dataSource={annnounce.announcment}
					renderItem={(item) => (
						<List.Item
							actions={[
								<Button
									color="default"
									type="link"
									onClick={() => updateAnnounce(item)}
									key="list-loadmore-edit"
								>
									Düzenle
								</Button>,
							]}
						>
							<List.Item.Meta title={item.topic} description={item.comment} />
						</List.Item>
					)}
				/>
			)}

			<Modal
				title="Duyuru Düzenle"
				okButtonProps={{
					htmlType: 'submit',
					form: 'news-update-form',
				}}
				open={show}
				onCancel={handleClose}
			>
				<Form
					id="news-update-form"
					name="basic"
					layout="vertical"
					initialValues={{
						remember: false,
					}}
					onFinish={updateAnnouncement}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					form={form}
				>
					<Form.Item>
						<Input name="id" type="hidden" value={modalData?.id} />
					</Form.Item>
					<Form.Item
						label="Duyuru Başlığı"
						name="topic"
						rules={[
							{
								required: true,
								message: 'Lütfen Duyuru Başlığı Giriniz!',
							},
						]}
					>
						<Input name="topic" placeholder={modalData?.topic} />
					</Form.Item>
					<Form.Item
						label="Duyuru İçeriği"
						name="comment"
						rules={[
							{
								required: true,
								message: 'Lütfen Duyuru İçeriği Giriniz!',
							},
						]}
					>
						<Input.TextArea name="comment" placeholder={modalData?.comment} />
					</Form.Item>
				</Form>
			</Modal>
		</Space>
	);
};

export default AnnounceCard;
