import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import CustomModal from '../../components/Modals';
import { useGetComplaintQuery } from '../../store/api';
import ComplaintCard from '../../components/ComplaintCard';
import ExportExcelModal from '../../components/ExportExcelModal';
import PageTitle from '../../components/PageTitle';
import { Row, Col, Button, Alert } from 'antd';

const Complaint = () => {
	const { data: complaint, isLoading } = useGetComplaintQuery();
	const [modalData, setModalData] = useState();
	const [openExportExcel, setOpenExportExcel] = useState(false);

	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title="Şikayetler">
					<div>
						<Button onClick={() => setOpenExportExcel(true)}>Excel'e Aktar</Button>
					</div>
				</PageTitle>
			</Col>
			<Col xs={2} sm={4} md={6} lg={8} xl={16}>
				{complaint?.message && (
					<Alert
						message="Hata"
						description="Şu an şikayet bulunmamaktadır."
						type="error"
						showIcon
					/>
				)}
				{isLoading && (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Yükleniyor...</span>
					</Spinner>
				)}
			</Col>
			<Col xs={24} xl={16}>
				<Row justify="start" gutter={[16, 16]}>
					{complaint?.complaint
						?.filter((val) => val.complaintStatus === 0)
						.map((item, index) => {
							return (
								<Col
									style={{ marginBottom: 20 }}
									key={item.id}
									xs={24}
									lg={{ span: 6, offset: index % 3 ? 1 : 0 }}
								>
									<ComplaintCard
										item={item}
										onClick={() => setModalData({ ...item, action: true })}
									/>
								</Col>
							);
						})}
				</Row>
			</Col>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={0}
				choiseType="complaint"
			/>
		</>
	);
};

export default Complaint;
